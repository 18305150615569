<template>
	<footer id="site-footer" class="flex-shrink-0 mt-auto">
		<div class="bg-white section copyright-section">
			<div class="px-4 py-4 text-[14px] text-center">
				Website Maintained By <a href="https://www.activamedia.com.sg/" target="_blank" class="hover:underline">Activa Media</a>. <span class="inline-block">All Rights Reserved.</span>
			</div>
		</div>
	</footer>
</template>

<script>
export default {

}
</script>

<style>   
	/* .wa-float{
		position:fixed;
		width:60px;
		height:60px;
		bottom:40px;
		right:40px;
		background-color:#25d366;
		color:#FFF;
		border-radius:50px;
		text-align:center;
			font-size:30px;
		box-shadow: 2px 2px 3px #999;
			z-index:100;
	}
	.my-float{
		margin: 15px auto 0;
	} */
</style>
